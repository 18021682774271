import FormSwitch from '@cfra-nextgen-frontend/shared/src/components/Form/FormSwitch';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

export default function ScreenerFormSwitch({
    filtersData,
    filterMetadataKey,
    control,
    layoutProps,
    getValues,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    defaultValue,
    uniqueIdWithinGroup,
    disabled,
}: FilterProps) {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const stackLabelWithContainer = filterSectionMetadata.stack_label_and_filter;

    const filter = (
        <FormSwitch
            name={combineIntoFormElementName({
                componentName: Components.Switch,
                filterMetadataKey,
                uniqueIdWithinGroup,
            })}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
            defaultValue={Boolean(defaultValue)}
            disabled={disabled}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            disableStacking
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
}
