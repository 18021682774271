import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';

export const getData = determineGetData({
    [ApiNames.PlatformManagementInternal]: {
        instance: API,
        instanceType: ApiInstanceTypes.AwsAmplify,
    },
});

export const UseData = determineUseData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
