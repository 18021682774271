import {
    determineAlertApi,
    determineGetFiltersData,
    determineGetScreenerData,
    determineGetScreenerDataSSR,
    determineOperateEntity,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UseData, getData } from 'utils/api';

const apiName = ApiNames.PlatformManagementInternal;
export const getScreenerData = determineGetScreenerData(UseData, apiName);
export const getScreenerDataSSR = determineGetScreenerDataSSR(getData, apiName);
export const getFiltersData = determineGetFiltersData(UseData, apiName);
export const operateEntity = determineOperateEntity(UseData, apiName);
export const sendAlert = determineAlertApi(UseData, apiName);
