export enum AppRoutePaths {
    Home = '*',
    Entitlements = '/entitlements',
    Users = '/users',
    ManageAccounts = '/manage-accounts',
    ManageUsers = '/manage-users',
    Account = '/account/:id',
    User = '/user/:id'
}

export enum PageNames {
    Home = 'Home',
    Entitlements = 'Entitlements',
    Users = 'Users',
    ManageAccounts = 'Manage Accounts',
    ManageUsers = 'Manage Users',
    Account = 'Account Profile',
    User = 'User Profile'
}
