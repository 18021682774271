import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { ProfileCard } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfileCard';
import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { ProfileFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/ProfileForm';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { OperationTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEntityAssociatedItems, getEntityFilterReqParams, getEntityScreenerReqParam } from './utils';

export type ProfilePageProps = {
    entityType: UserPlatformManagementEntityTypes;
    editProfileButtonText: string;
    getProfileTitle: (data: any) => string;
    getUpdateConfirmTitle?: (data: any) => string;
    CustomProfileForm?: (props: ProfileFormProps) => JSX.Element;
    entityId?: number;
};

export function ProfilePage(props: ProfilePageProps) {
    const { id } = useParams();
    const [entityId] = useState<number | undefined>(id ? parseInt(id) : undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (Number.isNaN(entityId)) {
            navigate('/');
        }
    }, [navigate, entityId]);

    // use useMemo to avoid unnecessary re-renders if useBlocker is used deeper in the tree
    const children = useMemo(() => {
        return <ProfilePageLocal {...props} entityId={entityId} />;
    }, [entityId, props]);

    return children;
}

const containerStyles = { p: 0 };

const containsProfileTitlePlaceholder = (template: string) => template.includes('{profileTitle}');

function ProfilePageLocal({
    entityType,
    editProfileButtonText,
    getProfileTitle,
    getUpdateConfirmTitle,
    CustomProfileForm,
    entityId,
}: ProfilePageProps) {
    const [profileTitle, setProfileTitle] = useState<string>();

    const entityAssociatedItems = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityAssociatedItems(entityType, entityId);
    }, [entityType, entityId]);

    const entityScreenerReqParams: ReturnType<typeof getEntityScreenerReqParam> | undefined = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityScreenerReqParam(entityType, entityId);
    }, [entityType, entityId]);

    const entityFiltersReqParams: ReturnType<typeof getEntityFilterReqParams> | undefined = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityFilterReqParams(entityType, OperationTypes.EDIT);
    }, [entityType, entityId]);

    const anyModalTitleContainsProfileTitle = useMemo(() => {
        const allModalTitles = entityAssociatedItems
            ?.map((item) => (item.modalsProps || [])?.map((props) => props?.title).join(' '))
            .join(' ');

        if (!allModalTitles) {
            return;
        }

        return containsProfileTitlePlaceholder(allModalTitles);
    }, [entityAssociatedItems]);

    const entityAssociatedItemsJsx = useMemo(() => {
        if (!entityAssociatedItems) {
            return null;
        }

        const result: Array<React.ReactNode> = [];
        for (const item of entityAssociatedItems) {
            const modalProps = item.modalsProps?.map((props) => {
                if (!containsProfileTitlePlaceholder(props.title)) {
                    return props;
                }

                return {
                    ...props,
                    title:
                        fillTemplate({
                            templateName: 'modalsPropsTitleTemplate',
                            template: props.title,
                            dataObject: {
                                profileTitle,
                            },
                        }) || '',
                };
            });

            result.push(
                <ScreenerHome
                    cardName={item.screenerProps.cardName || ''}
                    modalsProps={modalProps}
                    enableTopLeftActionPanel={item.screenerProps.enableTopLeftActionPanel}
                    enableTopRightActionPanel={false}
                    key={item.screenerProps.cardName}
                    rowLevelFiltersConfig={item.screenerProps.rowLevelFiltersConfig}
                    screenerRequestParams={item.screenerProps.screenerRequestParams}
                    useSSRMode={false}
                    maxGridContainerHeightPercentage={0}
                    unlimitedCalculatedHeight
                    {...(item?.screenerProps || {})}
                />,
            );
        }

        return result;
    }, [entityAssociatedItems, profileTitle]);

    const profileCardJsx = useMemo(() => {
        if (!entityId || !entityFiltersReqParams || !entityScreenerReqParams) {
            return null;
        }

        return (
            <ProfileCard
                id={entityId}
                profileType={entityType}
                filtersReqParams={entityFiltersReqParams}
                screenerReqParams={entityScreenerReqParams}
                getProfileTitle={getProfileTitle}
                getUpdateConfirmTitle={getUpdateConfirmTitle}
                editButtonText={editProfileButtonText}
                CustomProfileForm={CustomProfileForm}
                setProfileTitle={anyModalTitleContainsProfileTitle ? setProfileTitle : undefined}
            />
        );
    }, [
        editProfileButtonText,
        entityFiltersReqParams,
        entityId,
        entityScreenerReqParams,
        entityType,
        getProfileTitle,
        getUpdateConfirmTitle,
        CustomProfileForm,
        anyModalTitleContainsProfileTitle,
    ]);

    if (!entityId) {
        return null;
    }

    if (!entityAssociatedItems || !entityScreenerReqParams || !entityFiltersReqParams) {
        return null;
    }

    return (
        <>
            <ETFCard.ETFCard containerStyles={containerStyles}>{profileCardJsx}</ETFCard.ETFCard>
            {entityAssociatedItemsJsx}
        </>
    );
}
