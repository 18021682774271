import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { ItemDescription } from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { textFieldTheme } from '@cfra-nextgen-frontend/shared/src/components/Form/FormControlledTextField';
import { processFile } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { FileValidationConfig } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { appTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { FileTypeToReactDropzoneAccept } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, FormHelperText, ThemeProvider, createTheme } from '@mui/material';
import parse from 'html-react-parser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';

type FormFilePickerProps = {
    validationRules?: any;
    fileValidationConfig: FileValidationConfig;
} & CommonFormComponentProps;

const errorMessageTheme = createTheme(appTheme, {
    components: {
        MuiFormHelperText: {
            ...textFieldTheme?.components?.MuiFormHelperText,
        },
    },
});

type FilePickerFormState = {
    data: Array<any> | undefined;
    file: File | undefined;
    fileValidationErrorMessage: string;
};

export default function FormFilePicker({
    name,
    control,
    validationRules,
    fileValidationConfig,
    getValues,
    setValue,
}: FormFilePickerProps) {
    const inputId = `file-input-${name}`;
    const [formValue, setFormValue] = useState<Partial<FilePickerFormState>>();

    useEffect(() => {
        if (!formValue) {
            return;
        }

        if (!getValues || !setValue) {
            throw new Error('FormFilePicker exception. getValues or setValue is not defined.');
        }

        const currentValue = getValues?.(name) || {};

        setValue?.(name, { ...currentValue, ...formValue }, { shouldDirty: true, shouldValidate: true });
        setFormValue(undefined);
    }, [formValue, getValues, name, setValue]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const selectedFile = event.target.files[0];
        const newValue = { file: selectedFile, fileValidationErrorMessage: undefined, data: undefined };
        setFormValue(newValue);

        processFile({
            file: selectedFile,
            supportedFileExtensions: [fileValidationConfig.accept],
            showValidationError: (message) => setFormValue({ fileValidationErrorMessage: message }),
            validationConfig: fileValidationConfig.columns,
            setData: (data) => setFormValue({ data }),
        });

        // Reset the file input value to allow the same file to be selected again
        // event.target.value = ''; // now it works without this, uncomment if issue appear again
    };

    const onDrop = useCallback(
        (acceptedFiles: Array<File>) => {
            const inputElement = document.getElementById(inputId) as HTMLInputElement;
            if (!inputElement || acceptedFiles.length === 0) {
                return;
            }

            // Create a DataTransfer to simulate file drop
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(acceptedFiles[0]);
            // Assign the files to the input element
            inputElement.files = dataTransfer.files;
            // Manually trigger the onChange event
            const event = new Event('change', { bubbles: true });
            inputElement.dispatchEvent(event);
        },
        [inputId],
    );

    if (!Object.keys(FileTypeToReactDropzoneAccept).includes(fileValidationConfig.accept)) {
        throw new Error(`FormFilePicker exception. Unsupported file type: ${fileValidationConfig.accept}`);
    }

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: FileTypeToReactDropzoneAccept[fileValidationConfig.accept],
    });

    const defaultValue = useMemo(() => {
        return (
            getValues?.(name) || {
                data: undefined,
                file: undefined,
                fileValidationErrorMessage: undefined,
            }
        );
    }, [getValues, name]);

    let rules: { validate: (value: FilePickerFormState) => string | boolean } | undefined = undefined;

    if (validationRules?.required?.value) {
        const requiredMessage = validationRules?.required?.message;

        if (!requiredMessage) {
            throw new Error('FormFilePicker exception. Required validation message is not provided.');
        }

        rules = {
            validate: (value: FilePickerFormState) => {
                if (value.fileValidationErrorMessage) {
                    return false;
                }

                if (!value.file) {
                    return requiredMessage;
                }

                return true;
            },
        };
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState: { error } }) => {
                const _error: string | undefined = field.value.fileValidationErrorMessage || error?.message;

                return (
                    <div style={styles.mainContainer} {...{ ...getRootProps(), onClick: () => {} }}>
                        <input
                            id={inputId}
                            type='file'
                            accept={`.${fileValidationConfig.accept}`}
                            {...getInputProps()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                        />
                        <Box sx={styles.buttonAndDragAndDropContainer}>
                            <Box display={isDragActive ? 'none' : 'flex'} alignItems='center'>
                                <RoundedTextButton
                                    theme={roundedTextButtonThemeV2BorderRadius4}
                                    buttonText={'Upload File'}
                                    onClickCallback={() => document.getElementById(inputId)?.click()}
                                    sx={{ marginRight: '21px', minWidth: '155px' }}
                                />
                                <ItemDescription>
                                    {field.value.file
                                        ? `Selected file: ${(field.value.file as File)?.name}`
                                        : 'No file chosen'}
                                </ItemDescription>
                            </Box>
                            <Box sx={{ ...styles.dragAndDropContainer, display: isDragActive ? 'flex' : 'none' }}>
                                {isDragReject ? (
                                    <>
                                        <DoNotDisturbIcon sx={styles.dragAndDropIcon} />
                                        <ItemDescription sx={styles.dragAndDropText}>
                                            Unsupported file type...
                                        </ItemDescription>
                                    </>
                                ) : (
                                    <>
                                        <FileUploadIcon sx={styles.dragAndDropIcon} />
                                        <ItemDescription sx={styles.dragAndDropText}>
                                            Drop the files here...
                                        </ItemDescription>
                                    </>
                                )}
                            </Box>
                        </Box>
                        {_error && (
                            <ThemeProvider theme={errorMessageTheme}>
                                <FormHelperText error={true} style={styles.errorContainer}>
                                    {parse(_error)}
                                </FormHelperText>
                            </ThemeProvider>
                        )}
                    </div>
                );
            }}
        />
    );
}

const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        minHeight: '36px',
        width: '100%',
    },
    errorContainer: { alignSelf: 'flex-start' },
    dragAndDropIcon: { marginRight: '15px' },
    dragAndDropText: { lineHeight: '15px' },
    buttonAndDragAndDropContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%' },
    dragAndDropContainer: {
        height: '36px',
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
    },
};
