import { SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { fFunction } from './types';

type CommonButtonProps = {
    onClickCallback?: fFunction;
    content: string | React.ReactNode;
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    sx?: SxProps;
};

export const CommonButton = React.forwardRef<HTMLButtonElement, CommonButtonProps>((props, ref) => {
    const { theme, outlined, content, onClickCallback, ...rest } = props;
    return (
        <ThemeProvider theme={theme}>
            <Button ref={ref} variant={outlined ? 'outlined' : undefined} onClick={onClickCallback} {...rest}>
                {content}
            </Button>
        </ThemeProvider>
    );
});
