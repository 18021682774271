import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { MultipleModalsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext';
import { useContext } from 'react';

export function FiltersModalOpenButton({
    cardName,
    manageFiltersButtonText,
    multipleModalsContextKey,
}: {
    cardName: string;
    manageFiltersButtonText: string;
    multipleModalsContextKey?: string;
}) {
    const { setOpenFiltersModal } = useContext(FiltersModalContext);
    const { openModal } = useContext(MultipleModalsContext);

    return (
        <RoundedTextButton
            key='ScreenerFilterButton'
            theme={roundedTextButtonThemeV2BorderRadius4}
            buttonText={manageFiltersButtonText}
            onClickCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${manageFiltersButtonText}`,
                    cardName: cardName,
                });

                if (multipleModalsContextKey) {
                    openModal(multipleModalsContextKey);
                    return;
                }

                setOpenFiltersModal(true);
            }}
        />
    );
}
