import { AnalyticsTickerSearchContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import { CreateModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { MultipleModalsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { CreateFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/CreateForm';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScreenerCard, ScreenerCardProps } from './ScreenerCard';

export type ScreenerHomeProps = ScreenerCardProps & {};

export function ScreenerHome({
    cardName,
    searchPlaceholder,
    enableTopLeftActionPanel,
    enableTopRightActionPanel,
    useSSRMode,
    screenerRequestParams,
    analyticsCreateModalFormName,
    rowLevelFiltersConfig,
    maxGridContainerHeightPercentage,
    unlimitedCalculatedHeight,
    modalsProps,
}: ScreenerHomeProps) {
    const navigate = useNavigate();

    const screenerCard = useMemo(() => {
        return (
            <ScreenerCard
                cardName={cardName}
                modalsProps={modalsProps}
                searchPlaceholder={searchPlaceholder}
                enableTopLeftActionPanel={enableTopLeftActionPanel}
                enableTopRightActionPanel={enableTopRightActionPanel}
                useSSRMode={useSSRMode}
                screenerRequestParams={screenerRequestParams}
                rowLevelFiltersConfig={rowLevelFiltersConfig}
                analyticsCreateModalFormName={analyticsCreateModalFormName}
                maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
                unlimitedCalculatedHeight={unlimitedCalculatedHeight}
            />
        );
    }, [
        cardName,
        modalsProps,
        searchPlaceholder,
        enableTopLeftActionPanel,
        enableTopRightActionPanel,
        useSSRMode,
        screenerRequestParams,
        analyticsCreateModalFormName,
        rowLevelFiltersConfig,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
    ]);

    const multipleModalsJsx = useMemo(() => {
        if (!modalsProps) {
            return null;
        }

        return modalsProps.map((props) => {
            const onCreationSuccess: CreateFormProps['onCreationSuccessCallback'] = (createdItemId?: number) => {
                invalidateQueriesByKey('getFiltersData');
                invalidateQueriesByKey('getScreenerData');

                if (!createdItemId) {
                    return;
                }

                setTimeout(() => navigate(`/${props.entityType}/${createdItemId}`), 300);
            };

            return (
                <CreateModal
                    key={props.title}
                    modalTitle={props.title}
                    entityType={props.entityType}
                    onOperationSuccessSnackMessage={props.onOperationSuccessSnackMessage}
                    submitButtonName={props.submitButtonName}
                    onBeforeCreateSuccess={props.onBeforeCreateSuccess}
                    onCreationSuccessCallback={onCreationSuccess}
                    operationType={props.operationType}
                    description={props.description}
                />
            );
        });
    }, [navigate, modalsProps]);

    return (
        <ResultsContextProvider>
            <MultipleModalsContextProvider>
                <FiltersModalContextProvider>
                    <AnalyticsTickerSearchContextProvider>{screenerCard}</AnalyticsTickerSearchContextProvider>
                </FiltersModalContextProvider>
                {modalsProps && (
                    <SnackbarProvider
                        maxSnack={3}
                        classes={{
                            containerRoot: 'cfra-snackbar-root',
                        }}>
                        {multipleModalsJsx}
                    </SnackbarProvider>
                )}
            </MultipleModalsContextProvider>
        </ResultsContextProvider>
    );
}
