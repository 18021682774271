import { AgGridProps, defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { applyCustomFilterValueFormatter } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { EditButtons } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/EditButtons';
import {
    RowLevelFiltersConfig,
    SingleModalProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import {
    getFilterKeysToRowLevelFilter,
    getObjectWithFilter,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/rowFilters';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { FiltersModalOpenButton } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalOpenButton';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { SearchInput } from '@cfra-nextgen-frontend/shared/src/components/Screener/tickerSearch/SearchInput';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { ssrmMaxRowsToFetch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { SearchByParams, invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SxProps } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { ResultsAndExportRow } from './ResultsAndExportRow';
import { getRowHeightBasedOnListData } from './utils';

export type ScreenerCardProps = {
    cardName: string;
    modalsProps?: Array<SingleModalProps>;
    searchPlaceholder?: string;
    enableTopLeftActionPanel?: boolean;
    enableTopRightActionPanel?: boolean;
    useSSRMode?: boolean;
    screenerRequestParams?: SearchByParams;
    rowLevelFiltersConfig?: RowLevelFiltersConfig;
    analyticsCreateModalFormName?: string;
    maxGridContainerHeightPercentage?: AgGridProps['maxGridContainerHeightPercentage'];
    unlimitedCalculatedHeight?: AgGridProps['unlimitedCalculatedHeight'];
};

const topRightActionPanelStyles: SxProps = { alignItems: 'end' };
const topLeftActionPanelStyles: SxProps = { paddingBottom: '17px' };
const view = 'default';

export function ScreenerCard({
    cardName,
    modalsProps,
    searchPlaceholder = '',
    useSSRMode = true,
    screenerRequestParams = {},
    rowLevelFiltersConfig,
    enableTopLeftActionPanel = true,
    enableTopRightActionPanel = true,
    maxGridContainerHeightPercentage,
    unlimitedCalculatedHeight,
}: ScreenerCardProps) {
    const {
        chipStateManager: { chipStateDispatcher },
    } = useContext(ResultsContext);

    const rowLevelFiltersConfigEnabled = useMemo(() => {
        return Boolean(rowLevelFiltersConfig && Object.keys(rowLevelFiltersConfig?.requestParams || {}).length > 0);
    }, [rowLevelFiltersConfig]);

    const [defaultValues, setDefaultValues] = useState<Record<string, any>>();

    const { handleSubmit, formState, getValues, setValue, trigger, control, reset } = useForm({
        // defaultValues, // if set defaultValues, it doesn't work on defaultValues change
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    // handle changing defaultValues
    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const { searchTerm } = useContext(FiltersModalContext);
    const [enableEdit, setEnableEdit] = useState(false);
    const [formData, setFormData] = useState<RhFormData>();
    const { getScreenerData, getDataSource, getSsrDataExportFn, getFiltersData } = useContext(
        ProjectSpecificResourcesContext,
    );

    const submitHandler: () => void = useCallback(() => handleSubmit((data) => setFormData(data))(), [handleSubmit]);

    const rowLevelFiltersDataQueryResult = getFiltersData?.({
        ...rowLevelFiltersConfig?.requestParams,
        config: {
            enabled: rowLevelFiltersConfigEnabled,
        },
        noErrorOnNoKeyValuePairs: true,
    });

    const extendedScreenerRequestParams = useMemo(
        () => ({
            search: searchTerm,
            view: view,
            ...screenerRequestParams,
        }),
        [searchTerm, screenerRequestParams],
    );

    const getScreenerDataInputProps = useMemo(
        () => ({
            ...extendedScreenerRequestParams,
            ...(useSSRMode && { size: ssrmMaxRowsToFetch, from: 0 }),
        }),
        [extendedScreenerRequestParams, useSSRMode],
    );
    const screenerData = getScreenerData?.(getScreenerDataInputProps) as UseQueryResult<ScreenerData>;

    useEffect(() => {
        if (!chipStateDispatcher) {
            return;
        }

        if (screenerData.isLoading) {
            chipStateDispatcher({ type: 'SetResultCount', newState: { resultCount: -1 } });
            return;
        }

        chipStateDispatcher({
            type: 'SetResultCount',
            newState: {
                resultCount: screenerData.data?.results.total || 0,
            },
        });
    }, [screenerData.isLoading, screenerData.data?.results.total, chipStateDispatcher]);

    const SSRDataExportFn = useMemo(
        () =>
            getSsrDataExportFn?.({
                metadataFields: screenerData?.data?._metadata.fields || [],
                requestParams: extendedScreenerRequestParams,
            }),
        [screenerData?.data?._metadata.fields, extendedScreenerRequestParams, getSsrDataExportFn],
    );

    const gridRef = useRef<AgGridReact>(null);

    const resultsRow = useMemo(
        () => (
            <ResultsAndExportRow
                cardName={cardName}
                gridRef={gridRef}
                fieldMetadata={screenerData?.data?._metadata.fields}
                {...(useSSRMode && { SSRDataExportFn })}
            />
        ),
        [cardName, SSRDataExportFn, useSSRMode, screenerData?.data?._metadata.fields],
    );

    const openModalsButtons = useMemo(() => {
        if (!modalsProps) {
            return [];
        }

        return modalsProps.map((props) => {
            return (
                <FiltersModalOpenButton
                    cardName={cardName}
                    key={`topLeftActionPanelItems - ${props.title}`}
                    manageFiltersButtonText={props.buttonText || props.title}
                    multipleModalsContextKey={props.title}
                />
            );
        });
    }, [cardName, modalsProps]);

    const onEditChange = useCallback(
        (value: boolean) => {
            setEnableEdit(value);

            if (!value) {
                reset(defaultValues);
            }
        },
        [setEnableEdit, reset, defaultValues],
    );

    const onUpdate = useCallback(async (_: any) => {
        invalidateQueriesByKey('getFiltersData');
        await invalidateQueriesByKey('getScreenerData');
        setEnableEdit(false);
    }, []);

    const topRightActionPanelItems = useMemo(
        () => [
            <SearchInput
                key='SearchInput'
                cardName={cardName}
                inputStyles={{ marginRight: '0px' }}
                placeholder={searchPlaceholder}
            />,
        ],
        [cardName, searchPlaceholder],
    );

    const isNoInformationAvailable = useMemo(
        () =>
            !screenerData.data ||
            !screenerData.data._metadata ||
            !screenerData.data._metadata.fields ||
            screenerData.data._metadata.fields.length === 0 ||
            !screenerData.data._viewdata ||
            !screenerData.data._viewdata.fields ||
            screenerData.data._viewdata.fields.length === 0 ||
            !screenerData.data.results ||
            !screenerData.data.results.data ||
            screenerData.data.results.data.length === 0,
        [screenerData.data],
    );

    const rowFiltersDataAvailable = useMemo(
        () =>
            Boolean(
                !rowLevelFiltersDataQueryResult?.isLoading &&
                    rowLevelFiltersDataQueryResult?.data &&
                    rowLevelFiltersDataQueryResult?.data.filter_metadata,
            ),
        [rowLevelFiltersDataQueryResult?.data, rowLevelFiltersDataQueryResult?.isLoading],
    );

    const topLeftActionPanelItems = useMemo(() => {
        const result: Array<React.ReactNode> = [...openModalsButtons];
        if (rowFiltersDataAvailable && rowLevelFiltersDataQueryResult?.data) {
            result.push(
                <EditButtons
                    key='EditButtonsBlock'
                    formState={formState}
                    onEditChange={onEditChange}
                    editButtonText={`Edit ${cardName}`}
                    analyticsCardName={cardName}
                    requestPath={screenerRequestParams.path + '/_batch'}
                    formData={formData}
                    edit={enableEdit}
                    filtersData={rowLevelFiltersDataQueryResult.data}
                    onUpdate={onUpdate}
                    sectionKey={rowLevelFiltersConfig?.requestParams?.type}
                    requestDefaultValue={rowLevelFiltersConfig?.requestDefaultValue}
                />,
            );
        }

        return result;
    }, [
        cardName,
        onUpdate,
        rowFiltersDataAvailable,
        rowLevelFiltersDataQueryResult?.data,
        formData,
        enableEdit,
        formState,
        onEditChange,
        rowLevelFiltersConfig?.requestParams?.type,
        rowLevelFiltersConfig?.requestDefaultValue,
        screenerRequestParams.path,
        openModalsButtons,
    ]);

    // set up default values for row level filters
    useEffect(() => {
        if (!rowFiltersDataAvailable || isNoInformationAvailable || !screenerData.data) {
            return;
        }

        const formElementNameToDefaultValue: Record<string, any> = {};

        screenerData.data._viewdata.fields.forEach((viewdataFieldObject) => {
            const columnMetadataKey = Object.keys(viewdataFieldObject)[0];
            const viewdataObject = viewdataFieldObject[columnMetadataKey];
            const cellRendererParams = viewdataObject.cell_renderer_params;
            if (!cellRendererParams) {
                return;
            }

            const cellRendererParamWithFilter = getObjectWithFilter(cellRendererParams);

            if (!cellRendererParamWithFilter) {
                return;
            }

            const filterMetadataKey = cellRendererParamWithFilter['filter'];

            if (!filterMetadataKey) {
                throw new Error(
                    `unexpected filter value for cell_renderer_params in ${columnMetadataKey} - ${filterMetadataKey}`,
                );
            }

            if (!rowLevelFiltersDataQueryResult?.data?.filter_metadata[filterMetadataKey]) {
                throw new Error(
                    `unable to set up default values for row level filters. filter_metadata not found for ${filterMetadataKey}`,
                );
            }

            const filterMetadata = rowLevelFiltersDataQueryResult?.data?.filter_metadata[filterMetadataKey];
            const componentName = filterMetadata.component;

            if (!componentName) {
                return;
            }

            screenerData.data.results.data.forEach((rowData) => {
                let defaultValue = rowData[columnMetadataKey];

                if (!defaultValue) {
                    defaultValue = applyCustomFilterValueFormatter({
                        rowData: rowData,
                        viewdata: screenerData.data._viewdata,
                        metadata: screenerData.data._metadata,
                        fieldMetadata: filterMetadata.item_metadata,
                        returnRawCalculatedValue: true,
                    });
                }

                const formElementName = combineIntoFormElementName({
                    componentName,
                    filterMetadataKey,
                    uniqueIdWithinGroup: rowData.id,
                });
                formElementNameToDefaultValue[formElementName] = defaultValue;
            });
        });

        if (Object.keys(formElementNameToDefaultValue).length === 0) {
            return;
        }

        return setDefaultValues?.(formElementNameToDefaultValue);
    }, [
        isNoInformationAvailable,
        screenerData.data,
        setDefaultValues,
        rowFiltersDataAvailable,
        rowLevelFiltersDataQueryResult?.data?.filter_metadata,
    ]);

    const filterKeysToRowLevelFilter = useMemo(() => {
        if (!rowFiltersDataAvailable) {
            return;
        }

        if (!rowLevelFiltersConfig?.requestParams?.type) {
            throw new Error('rowLevelFiltersRequestParams.type is required for row level filters');
        }

        if (!rowLevelFiltersDataQueryResult?.data) {
            throw new Error('rowLevelFiltersDataQueryResult.data is required for row level filters');
        }

        return getFilterKeysToRowLevelFilter({
            parentSectionKey: rowLevelFiltersConfig?.requestParams?.type,
            rowLevelFiltersData: rowLevelFiltersDataQueryResult.data,
            getValues: getValues,
            setValue: setValue,
            trigger: trigger,
            control: control,
            enableEdit,
            submitHandler,
        });
    }, [
        rowFiltersDataAvailable,
        rowLevelFiltersConfig?.requestParams?.type,
        rowLevelFiltersDataQueryResult?.data,
        getValues,
        setValue,
        trigger,
        control,
        enableEdit,
        submitHandler,
    ]);

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (isNoInformationAvailable || !screenerData.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData(screenerData.data, cardName, filterKeysToRowLevelFilter);

        return result;
    }, [cardName, isNoInformationAvailable, screenerData.data, filterKeysToRowLevelFilter]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) => minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const dataSource = useMemo(
        () =>
            getDataSource?.({
                metadataFields: screenerData?.data?._metadata.fields || [],
                etfData: screenerData?.data?.results.data || [],
                requestParams: extendedScreenerRequestParams,
            }),
        [
            extendedScreenerRequestParams,
            screenerData?.data?._metadata.fields,
            screenerData?.data?.results.data,
            getDataSource,
        ],
    );

    const getRowHeight = useMemo(() => getRowHeightBasedOnListData(screenerData.data), [screenerData.data]);

    const resultChild = useMemo(() => {
        return (
            <AgGridSelectedRowsContextProvider>
                <AgGirdCard
                    label={cardName}
                    labelProps={{ width: '100%' }}
                    showDefaultExportButton={false}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowMultiSelectWithClick={true}
                    rowSelection='multiple'
                    suppressRowClickSelection={true}
                    customFlexibleColumns={customFlexibleColumns}
                    getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                    {...(enableTopLeftActionPanel ? { topLeftActionPanelItems, topLeftActionPanelStyles } : {})}
                    {...(enableTopRightActionPanel ? { topRightActionPanelItems, topRightActionPanelStyles } : {})}
                    topPanelSlot3Content={resultsRow}
                    labelPanelContainerStyles={{ paddingTop: '36px' }}
                    getRowHeight={getRowHeight}
                    {...(useSSRMode
                        ? { useSSRMode, getRowID, SSRrowsToFetch: ssrmMaxRowsToFetch, SSRDataSource: dataSource }
                        : { rowsData: screenerData?.data?.results.data })}
                    maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
                    unlimitedCalculatedHeight={unlimitedCalculatedHeight}
                />
            </AgGridSelectedRowsContextProvider>
        );
    }, [
        cardName,
        columnDefs,
        customFlexibleColumns,
        dataSource,
        enableTopLeftActionPanel,
        enableTopRightActionPanel,
        getResizableMinWidthForColumn,
        getRowHeight,
        gridRef,
        resultsRow,
        useSSRMode,
        screenerData?.data?.results.data,
        topLeftActionPanelItems,
        topRightActionPanelItems,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
    ]);

    return resultChild;
}
