import { Dispatch, createContext, useCallback, useMemo, useState } from 'react';

type MultipleModalsState = {
    isOpenModal: (modalId: string) => boolean;
    openModal: Dispatch<string>;
    closeModal: Dispatch<string>;
};

export const MultipleModalsContext = createContext<MultipleModalsState>({} as MultipleModalsState);

export function MultipleModalsContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [openedModals, setOpenedModals] = useState<Array<string>>([]);

    const isOpenModal: MultipleModalsState['isOpenModal'] = useCallback(
        (modalId) => {
            return openedModals.includes(modalId);
        },
        [openedModals],
    );

    const openModal = useCallback((modalId: string) => {
        setOpenedModals((prev) => [...prev, modalId]);
    }, []);

    const closeModal = useCallback((modalId: string) => {
        setOpenedModals((prev) => prev.filter((id) => id !== modalId));
    }, []);

    return (
        <MultipleModalsContext.Provider
            value={{
                isOpenModal,
                openModal,
                closeModal,
            }}>
            {memorizedChildren}
        </MultipleModalsContext.Provider>
    );
}
