import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import {
    DirtyFields,
    RhFormData,
    getDirtyData,
    getOperateEntityRequestBody,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CancelButtonText, ConfirmButtonText, SaveButtonText } from './utils';

export function OperateEntityWithConfirmation({
    requestPath,
    confirmModalText,
    disableConfirmationModal,
    enableSubmitButton,
    analyticsCardName,
    dirtyFields,
    formData,
    filtersData,
    onOperationSuccess,
    onOperationError,
    requestType,
    saveButtonText,
    modifyRequestBody,
    sectionKey,
    requestDefaultValue,
}: {
    requestPath: string;
    confirmModalText?: string;
    disableConfirmationModal?: boolean;
    enableSubmitButton: boolean;
    analyticsCardName: string;
    dirtyFields: DirtyFields;
    formData?: RhFormData;
    filtersData: FiltersData;
    onOperationSuccess: (data: any) => void;
    onOperationError?: (error: any) => void;
    requestType: RequestTypes;
    saveButtonText?: string;
    modifyRequestBody?: (requestBody: { [key: string]: any }) => { [key: string]: any };
    sectionKey?: string;
    requestDefaultValue?: Record<string, any>;
}) {
    const { operateEntity, userId } = useContext(ProjectSpecificResourcesContext);
    const [showConfirm, setShowConfirm] = useState(false);
    const [operationData, setOperationData] = useState<{ [key: string]: any } | undefined>();

    const operateEntityQuery = operateEntity?.(
        {
            path: requestPath,
            requestBody: operationData,
            config: {
                enabled: operationData !== undefined,
                cacheTime: 3000
            },
            noErrorOnNoKeyValuePairs: true,
        },
        requestType,
        false,
    );


    useEffect(() => {
        const refetchOperatedData = Boolean(
            operationData &&
                operateEntityQuery &&
                !operateEntityQuery.isLoading &&
                operateEntityQuery.data &&
                Object.keys(operateEntityQuery.data).length > 0,
        );
        if (!refetchOperatedData) {
            return;
        }

        if (operateEntityQuery?.data?.isErroredResponse) {
            setOperationData(undefined);
            onOperationError?.(operateEntityQuery?.data);
            return;
        }

        setOperationData(undefined);
        onOperationSuccess(operateEntityQuery?.data);
    }, [onOperationError, onOperationSuccess, operateEntityQuery, operationData]);

    const handleConfirmClick = useCallback(() => {
        if (formData && userId) {
            const dirtyData = getDirtyData(dirtyFields, formData);
            let requestBody = getOperateEntityRequestBody({
                formState: dirtyData,
                filtersData,
                userId,
                sectionKey,
                requestDefaultValue,
            });
            requestBody = modifyRequestBody?.(requestBody) || requestBody;
            setOperationData(requestBody);
        }

        globalThis.analytics?.registerAction?.({
            action: `click on ${ConfirmButtonText}`,
            cardName: analyticsCardName,
        });
    }, [
        analyticsCardName,
        dirtyFields,
        filtersData,
        formData,
        userId,
        modifyRequestBody,
        sectionKey,
        requestDefaultValue,
    ]);

    const saveButtonTextLocal = useMemo(() => saveButtonText || SaveButtonText, [saveButtonText]);

    const handleSaveChangesClick = useCallback(() => {
        if (!disableConfirmationModal) {
            setShowConfirm(true);
        } else {
            handleConfirmClick();
        }

        globalThis.analytics?.registerAction?.({
            action: `click on ${saveButtonTextLocal}`,
            cardName: analyticsCardName,
        });
    }, [analyticsCardName, disableConfirmationModal, handleConfirmClick, saveButtonTextLocal]);

    const handleCancelClick = useCallback(() => {
        setShowConfirm(false);
        globalThis.analytics?.registerAction?.({
            action: `click on ${CancelButtonText}`,
            cardName: analyticsCardName,
        });
    }, [analyticsCardName]);

    return (
        <>
            <RoundedTextButton
                type='submit'
                theme={roundedTextButtonThemeV2BorderRadius4}
                buttonText={saveButtonTextLocal}
                disabled={!enableSubmitButton}
                onClickCallback={handleSaveChangesClick}
            />
            <ConfirmationModal
                openModal={showConfirm && !disableConfirmationModal}
                modalText={confirmModalText || ''}
                cancelButtonText={CancelButtonText}
                confirmButtonText={ConfirmButtonText}
                cancelCallback={handleCancelClick}
                confirmCallback={handleConfirmClick}
            />
        </>
    );
}
