import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFCard';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFEmptyCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { CancelWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CancelWithConfirmation';
import { OperateEntityWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/OperateEntityWithConfirmation';
import {
    getEntityFilterReqParams,
    getRequestBody,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import {
    checkIfFilterInfoIsAvailable,
    noInformationAvailableCardStyles,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/utils';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { BasicForm, PublicBasicFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/BasicForm';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import {
    OperationTypes,
    RequestTypes,
    UserPlatformManagementEntityTypes,
    serverErrorToBeautifiedMessage,
} from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query/types/react/types';

export type CreateFormProps = {
    entityType: UserPlatformManagementEntityTypes;
    cancelButtonCallback: () => void;
    onOperationSuccessSnackMessage: string;
    openConfirmationModal: boolean;
    setOpenConfirmationModal: Dispatch<boolean>;
    submitButtonName?: string;
    onCreationSuccessCallback?: (createdItemId: number) => void;
    operationType: OperationTypes;
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filterData?: FiltersData) => void;
} & Omit<PublicBasicFormProps, 'filtersData'>;

export function CreateForm({
    analyticsCardName,
    entityType,
    cancelButtonCallback,
    onOperationSuccessSnackMessage,
    openConfirmationModal,
    setOpenConfirmationModal,
    submitButtonName,
    onBeforeCreateSuccess,
    onCreationSuccessCallback,
    operationType,
}: CreateFormProps) {
    const [formData, setFormData] = useState<RhFormData | undefined>();
    const [filtersData, setFiltersData] = useState<FiltersData>();
    const [requestBody, setRequestBody] = useState({} as any);

    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const { formState, control, getValues, setValue, handleSubmit, trigger, reset, resetField } = useForm({
        reValidateMode: 'onSubmit',
    });

    const { getFiltersData } = useContext(ProjectSpecificResourcesContext);
    const entityFiltersReqParams: SearchByParams = getEntityFilterReqParams(entityType, operationType);

    if (Object.keys(requestBody).length > 0) {
        entityFiltersReqParams['requestBody'] = getRequestBody(requestBody, formState.dirtyFields, getValues);
    }

    const filtersQueryResult = getFiltersData?.(entityFiltersReqParams) as UseQueryResult<FiltersData>;

    useEffect(() => {
        if (!filtersQueryResult.data) {
            return;
        }

        setFiltersData(filtersQueryResult.data);
    }, [filtersQueryResult.data]);

    const handleDependencies = useMemo(
        () => Object.values(filtersQueryResult.data?.filter_metadata || {}).some((item) => Boolean(item.dependencies)),
        [filtersQueryResult.data?.filter_metadata],
    );

    useEffect(() => {
        if (!handleDependencies || !filtersQueryResult.data) {
            return;
        }

        let metaData = filtersQueryResult.data.filter_metadata;
        let request_body = {} as any;

        for (let key in metaData) {
            if ((metaData[key].dependencies || []).length > 0) {
                let new_key = combineIntoFormElementName({
                    componentName: metaData[key].component,
                    filterMetadataKey: key,
                });
                request_body[new_key] = {
                    query_arg: metaData[key].source_field || '',
                    resetFields: metaData[key].dependencies?.map((item) =>
                        combineIntoFormElementName({
                            componentName: metaData[item].component,
                            filterMetadataKey: item,
                        }),
                    ),
                };
            }
        }

        setRequestBody(request_body);
    }, [handleDependencies, filtersQueryResult.data]);

    const clearChildControlsOnParentChange = useCallback(
        (fieldName?: string) => {
            if (fieldName !== undefined && fieldName in requestBody) {
                for (let field of requestBody[fieldName].resetFields) {
                    if (field in formState.dirtyFields) {
                        resetField(field);
                    }
                }
            }
        },
        [requestBody, formState.dirtyFields, resetField],
    );

    const onCancel = useCallback(() => {
        cancelButtonCallback();

        if (formState.isDirty || Object.keys(formState.errors).length > 0) {
            reset();
        }
    }, [cancelButtonCallback, formState.isDirty, reset, formState.errors]);

    const onOperationFailure = useCallback(
        (data: any) => {
            const beautifiedMessage =
                serverErrorToBeautifiedMessage[data?.message] ||
                `An error occurred while creating the item. ${data?.message}`;
            if (data?.isErroredResponse) {
                ShowSnack(
                    SnackMessageForm({
                        message: beautifiedMessage,
                    }),
                );
            }
        },
        [ShowSnack],
    );

    const onOperationSuccess = useCallback(
        (data: any) => {
            const id = data?.[entityType]?.id;

            if (!id) {
                return;
            }

            onBeforeCreateSuccess?.(data, formData, filtersQueryResult?.data);

            ShowSnack(
                SnackMessageForm({
                    message:
                        fillTemplate({
                            templateName: 'onOperationSuccessSnackMessage',
                            template: onOperationSuccessSnackMessage,
                            dataObject: data?.[entityType],
                        }) || '',
                }),
            );

            onCancel();
            onCreationSuccessCallback?.(id);
        },
        [ShowSnack, onCancel, onOperationSuccessSnackMessage, entityType, onCreationSuccessCallback, filtersQueryResult?.data, onBeforeCreateSuccess, formData],
    );

    const enableSubmitButton = useMemo(
        () => Boolean(formState.isDirty && formState.isValid),
        [formState.isDirty, formState.isValid],
    );

    const filterInfoNotAvailable = useMemo(
        () => checkIfFilterInfoIsAvailable(filtersQueryResult.isLoading, filtersQueryResult.data),
        [filtersQueryResult.isLoading, filtersQueryResult.data],
    );

    if (!filtersData && filtersQueryResult.isLoading) {
        return <ETFCard isLoading={filtersQueryResult.isLoading} />;
    }

    if (!filtersData || filterInfoNotAvailable) {
        return <NoInformationAvailable sx={noInformationAvailableCardStyles} />;
    }

    const modifyRequestBody = (requestBody: any) => {
        let metaData = filtersData.filter_metadata;

        Object.keys(metaData)
            .filter(
                (key) =>
                    (metaData[key].dropField?.create || false) &&
                    metaData[key].item_metadata.response_mapping?.default !== undefined,
            )
            .map((key) => {
                let fieldName = metaData[key].item_metadata.response_mapping?.default;
                if (fieldName !== undefined) {
                    delete requestBody[fieldName];
                }
                return '';
            });

        return requestBody;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
            <BasicForm
                filtersData={filtersData}
                analyticsCardName={`${analyticsCardName} Form`}
                getValues={getValues}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                setFormData={setFormData}
                trigger={trigger}
                onChangeClearHandler={clearChildControlsOnParentChange}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '1rem',
                    paddingX: 3.75,
                    paddingTop: 1,
                    paddingBottom: 4,
                }}>
                <CancelWithConfirmation
                    confirmModalText='Your changes will not be saved. Proceed?'
                    isDirty={formState.isDirty}
                    analyticsCardName={`${analyticsCardName}CancelConfirm`}
                    onCancel={onCancel}
                    externalOpenConfirmationModal={openConfirmationModal}
                    setExternalOpenConfirmationModal={setOpenConfirmationModal}
                />
                <OperateEntityWithConfirmation
                    requestType={RequestTypes.POST}
                    requestPath={entityType}
                    disableConfirmationModal
                    enableSubmitButton={enableSubmitButton}
                    analyticsCardName={`${analyticsCardName}SaveConfirm`}
                    dirtyFields={formState.dirtyFields}
                    formData={formData}
                    filtersData={filtersData}
                    onOperationSuccess={onOperationSuccess}
                    onOperationError={onOperationFailure}
                    saveButtonText={submitButtonName}
                    modifyRequestBody={modifyRequestBody}
                />
            </Box>
        </Box>
    );
}
