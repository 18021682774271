import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { SxProps, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { CommonButton } from './CommonButton';
import { IButtonProps } from './types';

type RoundedTextButtonTypes = IButtonProps & {
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    sx?: SxProps;
};

export function RoundedTextButton(props: RoundedTextButtonTypes) {
    const { theme, buttonText, outlined, onClickCallback, ...rest } = props;

    return (
        <CommonButton
            theme={theme}
            content={buttonText}
            onClickCallback={onClickCallback}
            outlined={outlined}
            {...rest}
        />
    );
}

type RoundedIconTextButtonTypes = IButtonProps & {
    theme: Theme;
    outlined?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    icon?: JSX.Element;
    tooltipText?: string;
};

export function RoundedIconTextButton(props: RoundedIconTextButtonTypes) {
    const { theme, buttonText, outlined, onClickCallback, icon, tooltipText, ...rest } = props;

    return (
        <ThemeProvider theme={tooltipTheme}>
            <Tooltip title={tooltipText}>
                <CommonButton
                    theme={theme}
                    content={
                        <>
                            {icon}
                            <Typography sx={icon !== undefined ? { paddingLeft: '8px' } : {}} variant='button' display='inline'>
                                {buttonText}
                            </Typography>
                        </>
                    }
                    onClickCallback={onClickCallback}
                    outlined={outlined}
                    {...rest}
                />
            </Tooltip>
        </ThemeProvider>
    );
}
