import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export function ManageAccounts() {
    return (
        <ScreenerHome
            cardName='Manage Accounts'
            modalsProps={[
                {
                    title: 'Create Account',
                    submitButtonName: 'Create Account',
                    entityType: UserPlatformManagementEntityTypes.Account,
                    onOperationSuccessSnackMessage: 'Account successfully created',
                },
            ]}
            screenerRequestParams={{ path: 'account' }}
            searchPlaceholder='Search for Account'
        />
    );
}
