import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Modal, ModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { MultipleModalsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { CreateForm, CreateFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/CreateForm';
import { OperationTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useContext, useMemo, useState } from 'react';

export type CreateModalProps = {
    modalTitle: string;
    entityType?: UserPlatformManagementEntityTypes;
    onOperationSuccessSnackMessage: string;
    submitButtonName?: string;
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filtersData?: FiltersData) => void;
    onCreationSuccessCallback?: CreateFormProps['onCreationSuccessCallback'];
    operationType?: OperationTypes;
    description?: ModalProps['description'];
};

export function CreateModal({
    modalTitle,
    entityType,
    onOperationSuccessSnackMessage,
    submitButtonName,
    onBeforeCreateSuccess,
    onCreationSuccessCallback,
    operationType = OperationTypes.CREATE,
    description,
}: CreateModalProps) {
    if (!entityType) throw new Error('Entity type is required for CreateModal.');

    const { isOpenModal, closeModal } = useContext(MultipleModalsContext);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

    const isOpenModalMemorized = useMemo(() => {
        return isOpenModal(modalTitle);
    }, [isOpenModal, modalTitle]);

    return (
        <Modal
            title={modalTitle}
            description={description || 'Required fields are marked by an asterisk *'}
            openModal={isOpenModalMemorized}
            callbackOnClose={() => setOpenConfirmationModal(true)}
            titleAndDescriptionContainerStyle={{
                padding: '32px 30px 0px',
            }}
            titleStyle={{
                display: 'flex',
            }}
            modalBoxStyles={{
                maxWidth: '480px',
                maxHeight: '85%',
            }}>
            <CreateForm
                analyticsCardName={modalTitle}
                entityType={entityType}
                cancelButtonCallback={() => closeModal(modalTitle)}
                onOperationSuccessSnackMessage={onOperationSuccessSnackMessage}
                openConfirmationModal={openConfirmationModal}
                setOpenConfirmationModal={setOpenConfirmationModal}
                submitButtonName={submitButtonName}
                onBeforeCreateSuccess={onBeforeCreateSuccess}
                onCreationSuccessCallback={onCreationSuccessCallback}
                operationType={operationType}
            />
        </Modal>
    );
}
