import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import {
    QueryFnType,
    SearchByParams,
    UseDataType,
    getFullRequestQuery,
    getRequestQuery,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export type GetScreenerData = ReturnType<typeof determineGetScreenerData>;
export type GetScreenerDataSSR = ReturnType<typeof determineGetScreenerDataSSR>;
export type GetFiltersData = ReturnType<typeof determineGetFiltersData>;
export type OperateEntity = ReturnType<typeof determineOperateEntity>;
export type SendAlert = ReturnType<typeof determineAlertApi>;

const defaultScreenerDataParams = {
    includeData: true,
    includeMetadata: true,
};

export const determineGetScreenerData = (UseData: UseDataType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams, queryKeyFirstElement?: string) {
        const { processResponse, ...restSearchByParams } = searchByParams;
        const extendedSearchByParams = { ...defaultScreenerDataParams, ...restSearchByParams };
        const screenerPath = `internal/screener/${extendedSearchByParams.path}`;
        const requestQuery = getRequestQuery(extendedSearchByParams, screenerPath);
        const queryKey = [
            queryKeyFirstElement || 'getScreenerData',
            ...Object.values(extendedSearchByParams),
            screenerPath,
        ];

        let data = UseData<ScreenerData>({
            apiName,
            requestQuery,
            queryKey,
            requestType: RequestTypes.POST,
            requestBody: extendedSearchByParams.requestBody || {},
            config: restSearchByParams.config,
        });
        if (processResponse) {
            return processResponse?.(data);
        }
        return data;
    };

export const determineGetScreenerDataSSR = (getData: QueryFnType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams) {
        const extendedSearchByParams = { ...defaultScreenerDataParams, ...searchByParams };
        const screenerPath = `internal/screener/${extendedSearchByParams.path}`;
        const requestQuery = getRequestQuery(extendedSearchByParams, screenerPath);

        return getData<ScreenerData>({
            apiName,
            requestQuery: getFullRequestQuery({ apiName, requestQuery }),
            requestType: RequestTypes.POST,
            requestBody: {},
        });
    };

export const determineGetFiltersData = (UseData: UseDataType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams, queryKeyFirstElement?: string) {
        const filtersPath = `internal/screener-filters/${searchByParams.path || ''}`;

        return UseData<FiltersData>({
            requestQuery: getRequestQuery(searchByParams, filtersPath),
            queryKey: [queryKeyFirstElement || 'getFiltersData', ...Object.values(searchByParams), filtersPath],
            apiName,
            requestType: RequestTypes.POST,
            requestBody: searchByParams.requestBody || {},
            config: searchByParams.config,
        });
    };

export const determineOperateEntity = (UseData: UseDataType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams, requestType: RequestTypes, invalidate: boolean = true) {
        const entityPath = `internal/entity/${searchByParams.path}`;
        const queryKey = ['operateEntity', ...Object.values(searchByParams), entityPath, requestType, invalidate];
        if (invalidate) {
            queryClient.invalidateQueries(queryKey);
        }
        return UseData<any>({
            apiName,
            requestType,
            requestQuery: entityPath,
            requestBody: searchByParams.requestBody,
            queryKey,
            config: searchByParams.config,
        });
    };

export const determineAlertApi = (UseData: UseDataType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams, requestType: RequestTypes, invalidate: boolean = true) {
        const entityPath = `alert`;
        const queryKey = ['alert', ...Object.values(searchByParams), entityPath, requestType, invalidate];
        if (invalidate) {
            queryClient.invalidateQueries(queryKey);
        }
        return UseData<any>({
            apiName,
            requestType,
            requestQuery: entityPath,
            requestBody: searchByParams.requestBody,
            queryKey,
            config: searchByParams.config,
        });
    };
