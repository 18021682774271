import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import {
    RhFormData,
    getFormValueByFilterKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import {
    RequestTypes,
    UserAlertTypes,
    UserPlatformManagementEntityTypes,
} from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useContext, useEffect, useState } from 'react';

export function ManageUsers() {
    const { sendAlert } = useContext(ProjectSpecificResourcesContext);
    const [userId, setUserId] = useState<number | undefined>();

    const sendAlertQuery = sendAlert?.(
        {
            requestBody: {
                users: [userId],
                alert_type: UserAlertTypes.CapitolInsightsWelcomeEmail,
            },
            config: {
                enabled: userId !== undefined,
            },
        },
        RequestTypes.POST,
        false,
    );

    useEffect(() => {
        if (sendAlertQuery?.isSuccess && !sendAlertQuery.isLoading && sendAlertQuery?.data?.data) {
            setUserId(undefined);
        }
    }, [sendAlertQuery]);

    function handleOnBeforeCreateSuccess(data: any, formData?: RhFormData, filtersData?: FiltersData) {
        const disableWelcomeEmailKey = 'user_management.user.disable_welcome_email';
        const userId = data?.['user']?.id;
        if (typeof userId === 'number' && filtersData && formData) {
            const disableWelcomeEmail = getFormValueByFilterKey(disableWelcomeEmailKey, formData, filtersData);
            if (!!!disableWelcomeEmail) {
                setUserId(userId);
            }
        }
    }

    return (
        <ScreenerHome
            cardName='Manage Users'
            modalsProps={[
                {
                    title: 'Create User',
                    submitButtonName: 'Create User',
                    entityType: UserPlatformManagementEntityTypes.User,
                    onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                    onBeforeCreateSuccess: handleOnBeforeCreateSuccess,
                },
            ]}
            screenerRequestParams={{ path: 'user' }}
            searchPlaceholder='Search for User'
            useSSRMode={false}
        />
    );
}
