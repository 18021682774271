import { ItemHeader } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { EditButtons } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/EditButtons';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { BasicForm, PublicBasicFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/BasicForm';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export type ProfileFormProps = {
    defaultValues: any;
    edit: boolean;
    editButtonText: string;
    id: number;
    onEditChange: (v: boolean) => void;
    onUpdate: (data: any) => void;
    profileType: string;
    title: string;
    updateConfirmTitle?: string;
    modifyRequestBody?: (requestBody: { [key: string]: any }) => { [key: string]: any };
} & PublicBasicFormProps;

export function ProfileForm({
    defaultValues,
    edit,
    onEditChange,
    onUpdate,
    filtersData,
    analyticsCardName,
    editButtonText,
    profileType,
    modifyRequestBody,
    id,
    updateConfirmTitle,
    title,
}: ProfileFormProps) {
    const [formData, setFormData] = useState<RhFormData | undefined>();

    const { formState, control, getValues, setValue, handleSubmit, trigger, reset } = useForm({
        // defaultValues: defaultValues, // set default values in the useEffect below to apply defaultValues changes
        reValidateMode: 'onSubmit',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const basicForm = useMemo(() => {
        return (
            <BasicForm
                filtersData={filtersData}
                analyticsCardName={analyticsCardName}
                getValues={getValues}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                setFormData={setFormData}
                trigger={trigger}
            />
        );
    }, [analyticsCardName, control, filtersData, getValues, handleSubmit, setValue, trigger]);

    const header = useMemo(() => <ItemHeader sx={{ p: '28px', pb: '12px' }}>{title}</ItemHeader>, [title]);
    const requestPath = useMemo(() => `${profileType}/${id}`, [profileType, id]);

    return (
        <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            {header}
            <Box paddingX='28px' paddingY='12px'>
                <EditButtons
                    formState={formState}
                    onEditChange={onEditChange}
                    editButtonText={editButtonText}
                    analyticsCardName={analyticsCardName}
                    updateConfirmTitle={updateConfirmTitle}
                    formData={formData}
                    edit={edit}
                    filtersData={filtersData}
                    onUpdate={onUpdate}
                    modifyRequestBody={modifyRequestBody}
                    requestPath={requestPath}
                />
            </Box>
            {basicForm}
        </Grid>
    );
}
