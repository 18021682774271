import { ETFCard, NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { getFiltersMetadataByViewType } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import { checkIfFilterInfoIsAvailable } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/utils';
import { ProfileForm, ProfileFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/ProfileForm';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { SearchByParams, invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import _ from 'lodash';
import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';

type ProfileCardProps = {
    getProfileTitle: (data: any) => string;
    id: number;
    profileType: string;
    filtersReqParams: SearchByParams;
    screenerReqParams: SearchByParams;
    editButtonText: string;
    getUpdateConfirmTitle?: (data: any) => string;
    CustomProfileForm?: (props: ProfileFormProps) => JSX.Element;
    setProfileTitle?: Dispatch<string>;
};

export function ProfileCard({
    CustomProfileForm,
    getProfileTitle,
    editButtonText,
    getUpdateConfirmTitle,
    id,
    profileType,
    filtersReqParams,
    screenerReqParams,
    setProfileTitle,
}: ProfileCardProps) {
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [filtersData, setFiltersData] = useState<FiltersData>();
    const [defaultValues, setDefaultValues] = useState<Record<string, any>>();

    const { getScreenerData, getFiltersData } = useContext(ProjectSpecificResourcesContext);

    const screenerQueryResult = getScreenerData?.(screenerReqParams) as UseQueryResult<ScreenerData>;
    const filtersQueryResult = getFiltersData?.(filtersReqParams) as UseQueryResult<FiltersData>;

    const updatedFiltersDataWithDefaultValues = useMemo(() => {
        if (filtersQueryResult?.data?.filter_metadata && screenerQueryResult?.data?.results?.data?.length === 1) {
            const filtersDataCopy: FiltersData = _.cloneDeep(filtersQueryResult.data || {});
            const {
                filtersMetadata,
                staticFiltersMetadata,
                defaultValues: _defaultValues,
                staticDefaultValues,
            } = getFiltersMetadataByViewType({
                screenerData: screenerQueryResult.data,
                filtersMetadata: filtersDataCopy?.filter_metadata,
            });

            filtersDataCopy['filter_metadata'] = filtersMetadata;

            return {
                filtersData: filtersDataCopy,
                defaultValues: _defaultValues,
                staticFiltersMetadata,
                staticDefaultValues,
            };
        }
    }, [filtersQueryResult.data, screenerQueryResult.data]);

    useEffect(() => {
        if (enableEdit) {
            setDefaultValues(updatedFiltersDataWithDefaultValues?.defaultValues);
            return;
        }

        setDefaultValues(updatedFiltersDataWithDefaultValues?.staticDefaultValues);
    }, [
        enableEdit,
        updatedFiltersDataWithDefaultValues?.defaultValues,
        updatedFiltersDataWithDefaultValues?.staticDefaultValues,
    ]);

    useEffect(() => {
        if (!updatedFiltersDataWithDefaultValues?.filtersData) {
            return;
        }

        if (enableEdit) {
            setFiltersData({ ...updatedFiltersDataWithDefaultValues.filtersData });
            return;
        }

        setFiltersData({
            ...updatedFiltersDataWithDefaultValues.filtersData,
            filter_metadata: updatedFiltersDataWithDefaultValues.staticFiltersMetadata,
        });
    }, [
        enableEdit,
        updatedFiltersDataWithDefaultValues?.filtersData,
        updatedFiltersDataWithDefaultValues?.staticFiltersMetadata,
    ]);

    const filterDataNotAvailable = useMemo(
        () => checkIfFilterInfoIsAvailable(filtersQueryResult.isLoading, filtersQueryResult.data),
        [filtersQueryResult.isLoading, filtersQueryResult.data],
    );

    const screenerDataNotAvailable = useMemo(
        () => !screenerQueryResult.data || !screenerQueryResult.data._metadata,
        [screenerQueryResult.data],
    );

    const handleOnEditChange = useCallback(
        (v: boolean) => {
            setEnableEdit(v);
        },
        [setEnableEdit],
    );

    const handleOnUpdate = useCallback(async (_: any) => {
        invalidateQueriesByKey('getFiltersData');
        await invalidateQueriesByKey('getScreenerData');
        setEnableEdit(false);
    }, []);

    const profileTitle = useMemo(() => {
        if (!screenerQueryResult.data?.results.data[0]) {
            return '';
        }

        return getProfileTitle(screenerQueryResult.data.results.data[0]);
    }, [getProfileTitle, screenerQueryResult.data?.results.data]);

    useEffect(() => {
        setProfileTitle?.(profileTitle);
    }, [profileTitle, setProfileTitle]);

    const profileFormProps = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        return {
            id,
            edit: enableEdit,
            onEditChange: handleOnEditChange,
            filtersData,
            onUpdate: handleOnUpdate,
            analyticsCardName: `${profileType}ProfileForm`,
            editButtonText: editButtonText,
            title: profileTitle,
            profileType: profileType,
            updateConfirmTitle: getUpdateConfirmTitle?.(screenerQueryResult.data?.results.data[0]),
            defaultValues,
        };
    }, [
        editButtonText,
        enableEdit,
        filtersData,
        handleOnUpdate,
        getUpdateConfirmTitle,
        handleOnEditChange,
        id,
        profileType,
        screenerQueryResult.data?.results.data,
        defaultValues,
        profileTitle,
    ]);

    const profileForm = useMemo(() => {
        if (!profileFormProps) {
            return null;
        }

        if (CustomProfileForm) {
            return <CustomProfileForm {...profileFormProps} />;
        }

        return <ProfileForm {...profileFormProps} />;
    }, [profileFormProps, CustomProfileForm]);

    if (
        filtersQueryResult.isLoading ||
        screenerQueryResult?.isLoading ||
        (!screenerDataNotAvailable && !filterDataNotAvailable && !filtersData)
    ) {
        return <ETFCard isLoading />;
    }

    if (screenerDataNotAvailable || filterDataNotAvailable) {
        return <NoInformationAvailable sx={{ p: '28px' }} />;
    }

    return profileForm;
}
